import { useAuth } from "@clerk/nextjs";
import { toast } from "react-hot-toast";

const useApi = () => {
  const { getToken } = useAuth();

  const api = {
    get: async (
      endpoint: string,
      successMessage?: string,
      errorMessage?: string
    ) => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_CONFIG_SERVICE_HOST}${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${await getToken({
                template: "config_service",
              })}`,
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          if (data.message) {
            throw new Error(data.message);
          } else {
            throw new Error("Network response was not ok");
          }
        }
        return data;
      } catch (error) {
        toast.error(errorMessage || "Error occurred while fetching data");
        throw error;
      }
    },
    post: async (
      endpoint: string,
      body: any,
      successMessage?: string,
      errorMessage?: string
    ) => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_CONFIG_SERVICE_HOST}${endpoint}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getToken({
                template: "config_service",
              })}`,
            },
            body: JSON.stringify(body),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          if (data.message) {
            throw new Error(data.message);
          } else {
            throw new Error("Network response was not ok");
          }
        }
        toast.success(successMessage || "Action completed successfully");
        return data;
      } catch (error: any) {
        toast.error(errorMessage || error.message);
        throw error;
      }
    },
    put: async (
      endpoint: string,
      body: any,
      successMessage?: string,
      errorMessage?: string
    ) => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_CONFIG_SERVICE_HOST}${endpoint}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getToken({
                template: "config_service",
              })}`,
            },
            body: JSON.stringify(body),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          if (data.message) {
            throw new Error(data.message);
          } else {
            throw new Error("Network response was not ok");
          }
        }
        toast.success(successMessage || "Action completed successfully");
        return data;
      } catch (error: any) {
        toast.error(errorMessage || error.message);
        throw error;
      }
    },
    patch: async (
      endpoint: string,
      body: any,
      successMessage?: string,
      errorMessage?: string
    ) => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_CONFIG_SERVICE_HOST}${endpoint}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getToken({
                template: "config_service",
              })}`,
            },
            body: JSON.stringify(body),
          }
        );
        if (response.status === 204) {
          toast.success(successMessage || "Action completed successfully");
          return;
        }

        const data = await response.json();
        if (!response.ok) {
          if (data.message) {
            throw new Error(data.message);
          } else {
            throw new Error("Network response was not ok");
          }
        }
        toast.success(successMessage || "Action completed successfully");
        return data;
      } catch (error: any) {
        toast.error(errorMessage || error.message);
        throw error;
      }
    },
    delete: async (
      endpoint: string,
      successMessage?: string,
      errorMessage?: string
    ) => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_CONFIG_SERVICE_HOST}${endpoint}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${await getToken({
                template: "config_service",
              })}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        toast.success(successMessage || "Action completed successfully");

        if (response.status === 204) {
          return;
        }

        return response.json();
      } catch (error) {
        toast.error(errorMessage || "Error occurred while deleting data");
        throw error;
      }
    },
    upload: async (
      endpoint: string,
      body: FormData,
      successMessage?: string,
      errorMessage?: string
    ) => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_CONFIG_SERVICE_HOST}${endpoint}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${await getToken({
                template: "config_service",
              })}`,
            },
            body,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        toast.success(successMessage || "Action completed successfully");

        return response.json();
      } catch (error) {
        toast.error(errorMessage || "Error occurred while uploading data");
        throw error;
      }
    },
  };

  return api;
};

export default useApi;
