"use client";
import Image from "next/image";
import Link from "next/link";

import { useEffect, useState } from "react";
import FeatureList from "./FeatureList";
import { Button } from "@/components/ui/button";
import { Check, ExternalLink, Link2, Loader2 } from "lucide-react";
import toast from "react-hot-toast";
import { useUser } from "@clerk/nextjs";
import useApi from "../hooks/useApi";
import WordRotate from "./magicui/word-rotate";
import TypingAnimation from "./magicui/typing-animation";

export default function Hero() {
  const { user, isLoaded } = useUser();
  const api = useApi();
  const [subscriptionStatus, setSubscriptionStatus] = useState<boolean | null>(
    null
  );
  const [business, setBusiness] = useState<{
    id: string;
    hasKeywords: boolean;
    hasTags: boolean;
    slug: string;
    hasResponseInstructions: boolean;
  } | null>(null);
  const [googleAccounts, setGoogleAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const [subscriptionRes, businessesRes, accountsRes] =
            await Promise.all([
              fetch("/api/stripe/subscriptions", { method: "POST" }),
              api.get("/businesses/onboarding"),
              api.get("/google-accounts"),
            ]);

          const subscriptionData = await subscriptionRes.json();
          const businessesData = await businessesRes;
          const accountsData = await accountsRes;

          setSubscriptionStatus(subscriptionData?.hasSubscription);
          setBusiness(businessesData?.id ? businessesData : null);
          setGoogleAccounts(accountsData);
        } catch (error) {
          console.error(error);
          toast.error("Failed to fetch data. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
      if (isLoaded && !user) {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin">
          <Loader2 size={64} />
        </div>
      </div>
    );
  }

  const hasBusinessWithKeywordsOrTags =
    business?.hasKeywords || business?.hasTags;

  return (
    <div className="">
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <Image
            src="/logo and text white.svg"
            alt="Revues logo"
            width={200}
            height={80}
            className="mx-auto mb-8"
          />
          <TypingAnimation
            className="text-4xl md:text-6xl font-bold text-white mb-6"
            duration={40}
            text="Revolutionize Your Customer Reviews"
          />
          <p className="text-xl text-gray-200 mb-8">
            Seamless AI-powered review management to boost your business
          </p>
          <Button
            asChild
            size="lg"
            className="bg-pink-500 hover:bg-pink-600 text-white"
          >
            {user ? (
              subscriptionStatus ? (
                <Link href="/businesses">Get Started</Link>
              ) : (
                <Link href="/plans">Get Started</Link>
              )
            ) : (
              <Link href="/sign-in">Sign In to Get Started</Link>
            )}
          </Button>
        </div>

        <div className="grid md:grid-cols-3 gap-8 text-white">
          <FeatureCard
            title="AI-Generated Responses"
            description="Respond to multiple reviews simultaneously with intelligent, context-aware AI"
            icon="🤖"
          />
          <FeatureCard
            title="SEO-Optimized Reviews"
            description="Encourage customers to leave reviews with your target keywords"
            icon="🔍"
          />
          <FeatureCard
            title="Direct Customer Feedback"
            description="Resolve issues privately before they become public reviews"
            icon="📮"
          />
        </div>

        <div className="mt-16">
          <h2 className="text-3xl font-bold text-white mb-8 text-center">
            How It Works
          </h2>
          <div className="mb-12">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Collecting Reviews
            </h3>
            <div className="grid md:grid-cols-5 gap-4">
              <StepCard
                done={subscriptionStatus || false}
                path={"/plans"}
                number="1"
                text="Buy a subscription"
              />
              <StepCard
                done={business !== null}
                path={business ? `/business/${business.id}` : "/businesses"}
                number="2"
                text="Add your business"
              />
              <StepCard
                done={hasBusinessWithKeywordsOrTags}
                path={
                  business
                    ? `/business/${business.id}?tab=keywords`
                    : "/businesses"
                }
                number="3"
                text="Add keywords and tags"
              />
              <StepCard
                done={Boolean(business?.slug)}
                path={
                  business ? `/business/${business.id}#slug` : "/businesses"
                }
                number="4"
                text="Generate QR code"
              />
              <StepCard number="5" text="Scan QR code or send link" />
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-white mb-4">
              Responding to Reviews
            </h3>
            <div className="grid md:grid-cols-4 gap-4">
              <StepCard
                done={business !== null}
                path={business ? `/business/${business.id}` : "/businesses"}
                number="1"
                text="Add your business (if not done)"
              />
              <StepCard
                done={business?.hasResponseInstructions}
                path={
                  business
                    ? `/business/${business.id}?tab=ai-responses`
                    : "/businesses"
                }
                number="2"
                text="Set up response preferences"
              />
              <StepCard
                done={googleAccounts.length > 0}
                path={"/accounts"}
                number="3"
                text="Connect Google Business account"
              />
              <StepCard
                done={googleAccounts.length > 0}
                path={"/accounts"}
                number="4"
                text="AI generates responses"
              />
              <StepCard
                path="/reviews"
                number="5"
                text="Check out your reviews and respond to them!"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FeatureCard({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: string;
}) {
  return (
    <div className="bg-white bg-opacity-10 rounded-lg p-6 backdrop-blur-lg transition-all duration-300 hover:bg-opacity-20 hover:shadow-lg hover:scale-105">
      <div className="text-4xl mb-4 transition-transform duration-300 hover:scale-110">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-200">{description}</p>
    </div>
  );
}

function StepCard({
  number,
  text,
  done,
  path = "/",
}: {
  number: string;
  text: string;
  done?: boolean;
  path?: string;
}) {
  return (
    <Link href={path}>
      <div
        className={`rounded-lg p-6 backdrop-blur-lg text-center transition-all duration-300 hover:bg-opacity-20 hover:shadow-lg hover:scale-105 ${
          done ? "bg-green-500 bg-opacity-20" : "bg-white bg-opacity-10"
        } relative`}
      >
        <div className="text-3xl text-white font-bold mb-2 transition-transform duration-300 hover:scale-110">
          {number}
        </div>
        <p className="text-gray-200">{text}</p>
        {done && (
          <div className="absolute bottom-2 right-2 text-green-400">
            <Check className="h-6 w-6" />
          </div>
        )}
        <div className="absolute top-2 right-2 text-white">
          <Link2 className="h-6 w-6" />
        </div>
      </div>
    </Link>
  );
}
